<template>
	<div class="home-nav">
		<div class="user-info">
			<div class="user-box" v-if="referrer_id && userInfo.user_types == 1">
				<img :src="showInfo.headimgurl" class="headimg" alt />
				<p class="nickname">{{ showInfo.nickname }}</p>
			</div>
			<div class="user-box" v-else-if="userInfo.user_types == 2">
				<img :src="userInfo.headimgurl" class="headimg" alt />
				<p class="nickname">{{ userInfo.nickname }}</p>
			</div>
			<img src="@/assets/images/logo.png" class="logo" alt v-else />
		</div>

		<div class="flex-right" v-if="types == 'home'">
			<div class="home-nav-middle">
				<input type="text" placeholder="搜索" @click="navgetTo" />
			</div>
			<router-link to="/position" tag="div" class="home-nav-left">
				<p>{{ curSiteInfo.site_name}}</p>
				<img src="@/assets/qiehuan.png" class="down" alt />
			</router-link>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		getUserInfo
	} from "@/http/api";

	export default {
		props: {
			types: {
				type: String,
				default () {
					return "";
				}
			}
		},
		data() {
			return {
				referrer_id: '', //推广员Id
				userInfo: {},
				showInfo: {},
				address: false,
				isShowChangeLocation: false,
				locationInfo: {},
			};
		},
		computed: {
			...mapState(["curSiteInfo"])
		},
		mounted() {
			this.userInfo = this.$LStorage.getItem('userInfo')
			this.referrer_id = this.$LStorage.getItem('referrer_id')
			if (this.userInfo.user_types == 1) {
				//  types=1为普通用户
				if (this.referrer_id) {
					// 获取推荐人个人信息
					this.getUserInfo();
				}
			}
		},

		methods: {
			//跳转页面
			navgetTo() {
				this.$router.push({
					path: "/search"
				});
			},

			//点击定位消失
			close() {
				this.address = false;
				this.$store.dispatch("changeShowLoction", true);
			},

			async getUserInfo() {
				const res = await getUserInfo({
					data: {
						user_id: this.referrer_id
					}
				});
				this.showInfo = res.data;
			}
		}
	};
</script>

<style scoped lang='scss'>
	.home-nav {
		width: calc(100% - 0.6rem);
		height: 0.6rem;
		padding: 0.2rem 0.3rem;
		font-size: 0.3rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 9999;
		background: #ffffff;

		.user-info {
			.logo {
				width: 1.74rem;
			}

			.user-box {
				display: flex;
				align-items: center;

				.headimg {
					width: 0.58rem;
					height: 0.58rem;
					border-radius: 50%;
					margin-right: 0.12rem;
					object-fit: cover;
				}

				.nickname {
					font-size: 0.3rem;
					color: #333;
					width: 2rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}

		.flex-right {
			display: flex;
			align-items: center;

			.home-nav-left {
				display: flex;
				align-items: center;
				justify-content: space-between;

				img {
					width: 0.24rem;
					height: 0.3rem;
					display: block;
					margin-right: 0.1rem;
				}

				.down {
					width: 0.2rem;
					height: 0.2rem;
					margin-left: 0.1rem;
				}
			}

			.home-nav-middle {
				width: 1.77rem;
				height: 0.6rem;
				margin-right: 0.18rem;

				input {
					width: 100%;
					height: 0.6rem;
					outline: none;
					border: none;
					border-radius: 0.3rem;
					font-size: 0.24rem;
					background-color: #f5f6f6;
					background-image: url("../../../assets/serch.png");
					background-size: 0.22rem 0.22rem;
					background-position: 0.2rem center;
					background-repeat: no-repeat;
					padding-left: 0.53rem;
					box-sizing: border-box;
				}
			}

			.home-nav-right {
				position: relative;

				img {
					height: 0.51rem;
				}

				p {
					position: absolute;
					top: 0;
					right: 0;
					width: 0.09rem;
					height: 0.09rem;
					background: #e50012;
					border-radius: 50%;
				}
			}
		}

		.qiehuan {
			width: calc(100% - 0.6rem);
			box-sizing: border-box;
			height: 0.73rem;
			position: absolute;
			top: 1rem;
			left: 0;
			right: 0;
			margin: auto;
			background: rgba(0, 0, 0, 0.78);
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #ffffff;
			z-index: 100;
			border-radius: 0.1rem;
			padding: 0 0.22rem;

			.left-cont {
				font-size: 0.24rem;

				span {
					color: #d8622c;
				}
			}

			.right-cont {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.change-btn {
					width: 1.5rem;
					height: 0.45rem;
					background: #d8622c;
					border-radius: 0.23rem;
					color: #ffffff;
					font-size: 0.2rem;
					text-align: center;
					line-height: 0.45rem;
					margin-right: 0.27rem;
				}

				.close {
					font-size: 0.2rem;
					color: #f5f6f6;
				}
			}
		}
	}
</style>
